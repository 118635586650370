import { EnglishIcon } from 'components/ui-kit/icons/iconComponents/EnglishIcon'
import { FrenchIcon } from 'components/ui-kit/icons/iconComponents/FrenchIcon'
import { GermanIcon } from 'components/ui-kit/icons/iconComponents/GermanIcon'
import { PortugalIcon } from 'components/ui-kit/icons/iconComponents/PortugalIcon'
import { RussianIcon } from 'components/ui-kit/icons/iconComponents/RussianIcon'
import { SpanishIcon } from 'components/ui-kit/icons/iconComponents/SpanishIcon'

/* ==========================================================================
  LANGUAGES
  ========================================================================== */
const LANGUAGES = [
  { label: 'English', languageCode: 'en', flag: EnglishIcon },
  { label: 'German', languageCode: 'de', flag: GermanIcon },
  { label: 'Spanish', languageCode: 'es', flag: SpanishIcon },
  { label: 'French', languageCode: 'fr', flag: FrenchIcon },
  { label: 'Portuguese', languageCode: 'pt', flag: PortugalIcon },
  { label: 'Russian', languageCode: 'ru', flag: RussianIcon }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
  Transaction types
========================================================================== */
export const TRANSACTION_TYPES = {
  BET: 'bet',
  WIN: 'win',
  ROLLBACK: 'rollback'
}

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
const TOKEN = 'admin_authtoken'
const LANGUAGE = 'admin_language'
const ADMIN_ID = 'admin_id'
const ROLE = 'operator_role'

/* ==========================================================================
Operator Roles
========================================================================== */
const OPERATOR_ROLES = {
  operator: 'OPERATOR',
  operatorUser: 'OPERATOR_USER'
}

/* ==========================================================================
Loader types
========================================================================== */
const LOADER_TYPE = {
  SCALE: 'scale',
  PULSE: 'pulse'
}

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
const TOASTER_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

/* ==========================================================================
  Loader Types
========================================================================== */
const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  Table Cell Type
========================================================================== */
const TABLE_CELL_TYPE = {
  status: 'status',
  switch: 'switch',
  button: 'button',
  date: 'date',
  text: 'text',
  number: 'number',
  default: 'default',
  updateIconButton: 'updateIconButton',
  deleteIconButton: 'deleteIconButton',
  actionCell: 'actionCell',
  enableDisable: 'enableDisable'
}

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
// const gamesSectionParentRoute = '/games'
// const playersSectionParentRoute = '/players'
// const performanceSectionParentRoute = '/performance'

const ROUTE_PATHS = {
  rootPath: '/',
  login: '/login',
  changePassword: '/change-password',
  resetPassword: '/reset-password/:adminId/:token',
  settings: '/settings',
  dashboard: '/dashboard',
  dailyStatistics: '/daily-statistics',
  profile: '/profile',
  gameSettings: '/game-settings',
  editGameSettings: '/game-settings/edit',
  users: '/users',
  usersDetail: '/users/:userId/details',
  createUser: '/users/create',
  editUser: '/users/:userId/edit',
  admins: '/admins',
  transactions: '/transactions',
  withdrawRequests: '/withdraw-requests',
  gameReports: '/game-reports/',
  notFound: '/not-found'
}

/* ==========================================================================
  DIALOG Types
========================================================================== */
const DIALOG_TYPE = {
  add: 'add',
  update: 'update',
  delete: 'delete',
  view: 'view',
  hide: ''
}

/* ==========================================================================
  Miscellaneous Constants
========================================================================== */
export const DEFAULT_PAGE_NO = 1
export const DEFAULT_CURRENCY_ID = 1
export const DEFAULT_CURRENCY_NAME = 'Gold Coin'
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'

// Export All constant variables
export {
  TOASTER_TYPE,
  LANGUAGES,
  TOKEN,
  LANGUAGE,
  ADMIN_ID,
  ROLE,
  OPERATOR_ROLES,
  ROUTE_PATHS,
  LOADER_TYPE,
  METHOD_TYPES,
  LOADER_HANDLER_TYPES,
  TABLE_CELL_TYPE,
  DIALOG_TYPE
}
