export const colors = {
  greyShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  white: '#fff',
  main: '#f3f3f4',
  neonBlue: 'rgba(199, 240, 214, 0.5)',
  darkYellow: '#F59E0B',
  pink: '#EF4444',
  red: '#FF0000',
  darkRed: '#B40000',
  forestGreen: '#22C55E',
  activeGreen: '#4BC500',
  shamrockGreen: '#00a762',
  blue: '#3B82F6',
  lightPurple: '#728CF8',
  purple: '#4F46E5',
  grey300: '#e8e8e8',
  grey400: '#CECECE',
  grey500: '#888',
  gunmetalBlue: '#2C3344',
  darkNavyBlue: '#0F172A',
  black: '#000',
  navyBlue: '#317ACF',
  neon: '#28c0c8'
}
