import { createSlice } from '@reduxjs/toolkit'
import { fetchDailyStatisticsReport, fetchDashboardReport } from 'redux-thunk/thunk/Dashboard/dashboard'

const initialState = {
  loading: false,
  report: null,
  dailyStatistics: {
    crashGame: {
      totalBets: 0,
      totalBetAmount: 0,
      totalWinAmount: 0,
      averageBetAmount: 0,
      totalProfit: 0,
      maxBetAmount: 0,
      maxEscapeRate: 0,
      totalWins: 0,
      maxWinAmount: 0,
      totalLost: 0,
      maxLossAmount: 0,
      totalRounds: 0,
      averageCrashRate: 0,
      maxCrashRate: 0
    }
  }
}

const gameReportsSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    updateDashboardReport: (state, action) => {
      let report = state.report
      if (action.payload?.games) {
        report = {
          ...action.payload,
          games: action.payload.games
        }
      }
      return {
        ...state,
        report
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardReport.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchDashboardReport.fulfilled, (state, action) => {
        let report = {}
        if (action.payload?.games) {
          report = {
            ...action.payload,
            games: action.payload.games
          }
        }
        return {
          ...state,
          loading: false,
          report
        }
      })
      .addCase(fetchDashboardReport.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(fetchDailyStatisticsReport.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchDailyStatisticsReport.fulfilled, (state, action) => {
        let dailyStatistics
        if (action.payload) {
          dailyStatistics = {
            ...state.dailyStatistics,
            ...action.payload
          }
        }
        return {
          ...state,
          loading: false,
          dailyStatistics
        }
      })
      .addCase(fetchDailyStatisticsReport.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
  }
})

export const { updateDashboardReport } = gameReportsSlice.actions

export default gameReportsSlice.reducer
