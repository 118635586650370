import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCrashGameReportService } from 'network/services/game.service'

export const fetchCrashGameReport = createAsyncThunk(
  'fetch/crashGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getCrashGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
