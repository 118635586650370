import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Login1Icon } from 'components/ui-kit/icons/iconComponents/Login1Icon'
import { LoginBg2Icon } from 'components/ui-kit/icons/iconComponents/LoginBg2Icon'
import CustomButton from 'components/ui-kit/Button/index'
import CustomTextField from 'components/ui-kit/TextField'
import CustomTypography from 'components/ui-kit/Typography'
import { LOADER_TYPE } from 'utils/constants'
import { connect } from 'react-redux'
import { LoginContainer, LoginIconContainer, RootLoginContainer, WelcomeTextContainer } from './styles'
import { adminLogin } from 'redux-thunk/thunk/Auth/Auth'
import { useLoginController } from './controller/useLoginController'
import AppLoader from 'components/ui-kit/AppLoader'

const Login = ({ loginLoading, adminLoginThunk }) => {
  const { t, otpRequired, otp, setOtp, handleSubmit, values, handleChange, errors } = useLoginController({ adminLoginThunk })

  return (
    <Grid container>
      <Grid item xs={12} lg={6} sx={{ position: 'relative' }}>
        <RootLoginContainer>
          <LoginContainer>
            <CustomTypography
              value={t('login')}
              variant='h3'
              sx={{
                fontWeight: 'bold'
              }}
            />
            <br />
            <CustomTextField
              sx={{ marginBottom: '20px' }}
              label={t('email')}
              placeholder={t('email')}
              variant='outlined'
              fullWidth
              id='email'
              name='email'
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              helperText={errors.email}
              enablevalidation={Boolean(values.email || errors.email)}
            />
            <CustomTextField
              sx={{ marginBottom: '20px' }}
              label={t('password')}
              type='password'
              placeholder={t('password')}
              variant='outlined'
              name='password'
              id='password'
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              helperText={errors.password}
              enablevalidation={Boolean(values.password || errors.password)}
            />

            {otpRequired && (
              <CustomTextField
                label={t('Enter OTP')}
                variant='outlined'
                fullWidth
                id='otp'
                name='otp'
                type='number'
                value={otp}
                sx={{ letterSpacing: '10px' }}
                onChange={(e) => {
                  const value = e.target.value
                  String(value).length <= 6 && setOtp(value)
                }}
              />)}

            <CustomButton
              variant='contained'
              disabled={Boolean(errors.email) || Boolean(errors.password) || !values.email.length || loginLoading}
              onClick={handleSubmit}
              sx={{ backgroundColor: '#1e293b' }}
            >
              {
                loginLoading
                  ? <AppLoader variant={LOADER_TYPE.PULSE} size={5} />
                  : <CustomTypography sx={{ fontWeight: 'bold' }} value={t('login')} />
              }
            </CustomButton>
          </LoginContainer>
        </RootLoginContainer>

        <LoginIconContainer>
          <Login1Icon style={{ height: '100%', width: '100%' }} />
        </LoginIconContainer>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: { xs: 'none', lg: 'block' },
          position: 'relative'
        }}
      >
        <WelcomeTextContainer>
          <CustomTypography
            variant='h2'
            align='center'
            value={t('WelcomeToOurCommunity')}
            sx={{ color: (theme) => theme.colors.white, padding: '20px' }}
          />
        </WelcomeTextContainer>

        <LoginIconContainer>
          <LoginBg2Icon style={{ height: '100%', width: '100%' }} />
        </LoginIconContainer>
      </Grid>
    </Grid>
  )
}

Login.propTypes = {
  loginLoading: PropTypes.bool,
  adminLoginThunk: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    loginLoading: state.loader.submitButtonLoader
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    adminLoginThunk: (values) => dispatch(adminLogin(values))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
