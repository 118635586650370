import { styled } from '@mui/material'
import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AppLoader from 'components/ui-kit/AppLoader'
import { LOADER_TYPE } from 'utils/constants'
import CustomRoutes from './routes'

const Routes = ({ pageLoader }) => {
  return (
    <Router>
      <Suspense fallback={
        <StyledLoaderDiv>
          {!pageLoader && <AppLoader variant={LOADER_TYPE.SCALE} />}
        </StyledLoaderDiv>
       }
      >
        <CustomRoutes />
      </Suspense>
    </Router>
  )
}

const StyledLoaderDiv = styled('div')({
  position: 'fixed',
  top: '50%',
  left: '50%',
  zIndex: 1
})

export default Routes
