import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllCurrenciesService = () => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/currencies', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getTransactionReportService = () => {
  return axiosInstance(METHOD_TYPES.get, '/transactions/report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
