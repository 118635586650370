import { createAsyncThunk } from '@reduxjs/toolkit'
import { getGamesListService, getGamesSettingsService, updateGamesSettingsService } from 'network/services/gameSettings.service'

export const getGamesList = createAsyncThunk(
  'get/GamesList',
  async (data, thunkApi) => {
    try {
      const res = await getGamesListService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getGamesSettings = createAsyncThunk(
  'get/GamesSettings',
  async (data, thunkApi) => {
    try {
      const res = await getGamesSettingsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
export const updateGamesSettings = createAsyncThunk(
  'update/GamesSettings',
  async ({ gameId, updates, onSuccessRedirect }, thunkApi) => {
    try {
      const res = await updateGamesSettingsService({ gameId, updates })
      onSuccessRedirect()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
