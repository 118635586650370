import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getGamesListService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/games/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getGamesSettingsService = (payload) => {
  return axiosInstance(METHOD_TYPES.get, '/games/settings', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: payload
  })
}

export const updateGamesSettingsService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/games/update-settings', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Updated Game Settings Successfully'
  })
}
