import { createSlice } from '@reduxjs/toolkit'
import { fetchCrashGameReport } from 'redux-thunk/thunk/GameReports/gameReports'

const initialState = {
  crashGameReport: null
}

const gameReportsSlice = createSlice({
  name: 'gameReportsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCrashGameReport.fulfilled, (state, action) => {
        return {
          ...state,
          crashGameReport: action.payload
        }
      })
  }
})

export default gameReportsSlice.reducer
