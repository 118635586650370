import PropTypes from 'prop-types'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import AppHeader from 'components/ui-kit/AppHeader/index'
import { connect } from 'react-redux'
// import LanguageMenu from './components/LanguageMenu'
// import ProfileMenu from './components/ProfileMenu'
// import Notification from './components/Notification'
import { RightSideOptionContainer, StyledIconButton } from './styles'
import { handleDrawerToggle } from 'redux-thunk/redux/Ham/hamSlice'
import { adminLogout } from 'redux-thunk/thunk/Auth/Auth'
import { handleLanguageChange } from 'redux-thunk/redux/Auth/authSlice'
import { getTranslation } from 'helpers/index'
import { openWarningToaster } from 'utils/common-services/toaster.services'
import { useHeaderController } from './controller/useHeaderController'
import CustomButton from 'components/ui-kit/Button/index'
import CustomTypography from 'components/ui-kit/Typography/index'
import { ROUTE_PATHS } from 'utils/constants/index'

const Header = ({
  isSideNavOpen,
  handleDrawerToggle,
  handleLanguageChange,
  updateOperatorLanguageThunk,
  selectedLanguage,
  showNotification,
  adminLogout,
  name
}) => {
  const { navigate } = useHeaderController({
    updateOperatorLanguageThunk,
    handleLanguageChange
  })

  return (
    <AppHeader
      openDrawer={isSideNavOpen}
      handleDrawer={handleDrawerToggle}
      left={
        (
          <StyledIconButton
            color='inherit'
            aria-label='open drawer'
            id='expand_sidebar'
            onClick={handleDrawerToggle}
            edge='start'
            mr={2}
          >
            <MenuIcon sx={{ fill: (theme) => theme.colors.white }} />
          </StyledIconButton>
          )
      }
      right={
        <RightSideOptionContainer>
          {/* <LanguageMenu
            toggleLanguageMenu={toggleLanguageMenu}
            anchorElLanguage={anchorElLanguage}
            handleChangeLanguage={handleChangeLanguage}
            updateOperatorLanguageThunk={updateOperatorLanguageThunk}
            selectedLanguage={selectedLanguage}
          />
          <ProfileMenu
            adminLogout={adminLogout}
            name={name}
            NAV_MENU_OPTIONS={NAV_MENU_OPTIONS}
            setAnchorElUser={setAnchorElUser}
            navigate={navigate}
            t={t}
            anchorElUser={anchorElUser}
            toggleUserMenu={toggleUserMenu}
          /> */}
          <CustomButton
            variant='contained'
            sx={{
              backgroundColor: (theme) => theme.colors.black,
              '&:hover': {
                backgroundColor: (theme) => theme.colors.grey500
              }
            }}
            onClick={() => navigate(ROUTE_PATHS.settings)}
          >
            <ManageAccountsIcon sx={{ fill: (theme) => theme.colors.white, marginRight: '10px' }} />
            <CustomTypography
              sx={{ fontWeight: 'bold' }}
              value='Profile'
            />
          </CustomButton>
          <CustomButton
            variant='contained'
            id='logout-action'
            sx={{
              backgroundColor: (theme) => theme.colors.black,
              '&:hover': {
                backgroundColor: (theme) => theme.colors.grey500
              }
            }}
            onClick={() => adminLogout(navigate)}
          >
            <LogoutIcon sx={{ fill: (theme) => theme.colors.white, marginRight: '10px' }} />
            <CustomTypography
              sx={{ fontWeight: 'bold' }}
              value='Logout'
            />
          </CustomButton>
        </RightSideOptionContainer>
      }
    />
  )
}

Header.propTypes = {
  isSideNavOpen: PropTypes.bool,
  handleDrawerToggle: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.oneOf(['en', 'de', 'es', 'fr', 'ru', 'pt']),
  updateUserInformationThunk: PropTypes.func,
  handleLanguageChange: PropTypes.func,
  adminLogout: PropTypes.func.isRequired,
  name: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    isSideNavOpen: state.ham.openHam,
    selectedLanguage: state.auth.language,
    showNotification: PropTypes.func,
    name: state.auth.name
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleDrawerToggle: () => dispatch(handleDrawerToggle()),
    updateOperatorLanguageThunk: ({ languageCode }) => {},
    handleLanguageChange: (value) => dispatch(handleLanguageChange(value)),
    showNotification: () => openWarningToaster(dispatch, getTranslation('youDontHavePendingNotifications')),
    adminLogout: (navigate) => dispatch(adminLogout({ navigate }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
