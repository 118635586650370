import CustomButton from 'components/ui-kit/Button/index'
import CustomTypography from 'components/ui-kit/Typography/index'
import { useContext, useLayoutEffect } from 'react'
import { UNSAFE_NavigationContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ErrorHandler = ({ resetErrorBoundary }) => {
  const { t } = useTranslation()
  const navigation = useContext(UNSAFE_NavigationContext).navigator

  useLayoutEffect(() => {
    if (navigation) {
      navigation.listen(() =>
        resetErrorBoundary()
      )
    }
    // eslint-disable-next-line
  }, [navigation])

  return (
    <>
      <div style={{ margin: '4% 5%', lineHeight: '50px' }}>
        <CustomTypography value={t('oopsSomethingWentWrong')} variant='h4' sx={{ fontWeight: 'bold' }} />
        <br />
        <CustomTypography value={t('pleaseRefresh')} variant='h6' />
        <br />
        <CustomButton onClick={() => { resetErrorBoundary() }}>
          {t('tryAgain')}
        </CustomButton>
      </div>
    </>
  )
}

export default ErrorHandler
