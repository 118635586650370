import PropTypes from 'prop-types'
import CustomTypography from '../Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const LabelContainer = ({
  showPassword,
  setShowPassword,
  setCustomizeType,
  isPasswordField,
  children,
  type,
  label,
  required
}) => {
  switch (type) {
    case 'none':
      return children
    case 'filter':
      return (
        <div style={{ width: '280px' }} type={type}>
          <div>
            <CustomTypography
              sx={{
                color: (theme) => theme.colors.gunmetalBlue,
                fontSize: '12px',
                fontWeight: 600
              }}
              variant='subtitle1'
              value={`${label}`}
            />
          </div>
          {children}
        </div>
      )

    default:
      return (
        <div style={{ width: 'fit-content', height: '100px' }}>
          <div style={{ textAlign: 'start' }}>
            <CustomTypography
              sx={{ color: (theme) => theme.colors.gunmetalBlue }}
              variant='subtitle1'
              value={label}
            />
            {required && (
              <CustomTypography
                sx={{ color: (theme) => theme.colors.red }}
                variant='subtitle1'
                value='*'
              />
            )}
            <span style={{ float: 'right' }}>
              {isPasswordField &&
                (!showPassword
                  ? (
                    <VisibilityIcon
                      sx={{ fill: '#989898', width: '20px' }}
                      onClick={() => {
                        setShowPassword(true)
                        setCustomizeType('text')
                      }}
                    />
                    )
                  : (
                    <VisibilityOffIcon
                      sx={{ fill: '#989898', width: '20px' }}
                      onClick={() => {
                        setShowPassword(false)
                        setCustomizeType('password')
                      }}
                    />
                    ))}
            </span>
          </div>
          {children}
        </div>
      )
  }
}

export default LabelContainer

LabelContainer.defaultProps = {
  type: 'default',
  label: ''
}

LabelContainer.propTypes = {
  type: PropTypes.oneOf(['filter', 'default', 'dateFilter', 'none']),
  label: PropTypes.string,
  children: PropTypes.element.isRequired
}
