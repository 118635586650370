import { createSlice } from '@reduxjs/toolkit'
import { getGamesList, getGamesSettings, updateGamesSettings } from 'redux-thunk/thunk/GameSettings/gameSettings'

const initialState = {
  games: null,
  settings: null
}

const gamesSettingsSlice = createSlice({
  name: 'gameSettingsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getGamesList.fulfilled, (state, action) => {
        const games = {}
        if (action?.payload?.rows) {
          games.count = action.payload.count
          games.rows = action.payload.rows
        }
        return {
          ...state,
          games
        }
      })
      .addCase(getGamesSettings.fulfilled, (state, action) => {
        const settings = {}
        if (action?.payload?.rows) {
          settings.count = action.payload.count
          settings.rows = action.payload.rows
        }
        return {
          ...state,
          settings
        }
      })
      .addCase(updateGamesSettings.fulfilled, (state, action) => {
        return {
          ...state,
          settings: action.payload.settings
        }
      })
  }
})

export default gamesSettingsSlice.reducer
