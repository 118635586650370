import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NAV_MENU_OPTIONS = {
  logout: 'logout',
  settings: 'settings',
  profile: 'profile'
}

export const useHeaderController = ({
  updateOperatorLanguageThunk,
  handleLanguageChange
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [anchorElLanguage, setAnchorElLanguage] = useState(null)

  const handleChangeLanguage = (languageCode) => {
    handleLanguageChange(languageCode)
    setAnchorElLanguage(null)
    i18n.changeLanguage(languageCode)
    updateOperatorLanguageThunk({ languageCode })
  }

  const toggleLanguageMenu = (event, closeMenu) => {
    if (closeMenu) {
      setAnchorElLanguage(null)
    } else {
      setAnchorElLanguage(event.currentTarget)
    }
  }

  const toggleUserMenu = (event, closeMenu) => {
    if (closeMenu) {
      setAnchorElUser(null)
    } else {
      setAnchorElUser(event.currentTarget)
    }
  }

  return {
    anchorElUser,
    toggleUserMenu,
    t,
    navigate,
    NAV_MENU_OPTIONS,
    setAnchorElUser,
    anchorElLanguage,
    handleChangeLanguage,
    toggleLanguageMenu
  }
}
