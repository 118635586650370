export const SuccessMessage = {
  login: 'loggedInSuccess',
  logout: 'loggedOutSuccess',
  changePassword: 'passwordUpdateSuccess',
  playerStatusUpdate: 'updatePlayerStatusSuccess',
  createOperator: 'operatorUserCreatedSuccess',
  resetPassword: 'resetPasswordSuccess',
  operatorDelete: 'operatorDeletedSuccess',
  operatorStatusUpdate: 'operatorStatusUpdateSuccess',
  resetPasswordSuccessfully: 'resetPasswordSuccessfully',
  resetPasswordLinkGenerated: 'resetPasswordLinkGeneratedSuccessfully',
  adminDetailsUpdated: 'adminDetailsUpdated',
  twoFactorEnabledSuccessfully: 'twoFactorEnabledSuccessfully',
  twoFactorDisabledSuccessfully: 'twoFactorDisabledSuccessfully'
}

export const ErrorMessage = {
  internalServerError: 'internalServerError',
  unAuthorized: 'unAuthorized'
}

export const errorCodeToMessage = new Map([
  [600, 'internalServerError'],
  [601, 'loginFailedError'],
  [602, 'invalidCredentialsError'],
  [603, 'invalidTokenError'],
  [604, 'invalidSessionError'],
  [605, 'invalidAccessError'],
  [606, 'nonOperationalError'],
  [2001, 'createUserError'],
  [2002, 'operatorNotExistsError'],
  [2003, 'operatorPlayerNotExists'],
  [2004, 'dataMismatchError'],
  [2005, 'userNotFoundError'],
  [2006, 'duplicateEmailError'],
  [2007, 'operatorUserIsBlocked']
])
