import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllPlayersService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getPlayerDetailService = (userId) => {
  return axiosInstance(METHOD_TYPES.get, '/users/details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { userId }
  })
}

export const createPlayerService = (userData) => {
  return axiosInstance(METHOD_TYPES.post, '/users/create', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Player created successfully'
  })
}

export const updatePlayerService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/users/update', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Player updated successfully'
  })
}

export const updatePlayerStatusService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/users/update-status', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: 'Player status updated successfully'
  })
}
