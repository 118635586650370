import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getPlayerDetailService,
  createPlayerService,
  getAllPlayersService,
  updatePlayerService,
  updatePlayerStatusService
} from 'network/services/players.service'

export const fetchAllPlayers = createAsyncThunk(
  'fetch/allPlayers',
  async (data, thunkApi) => {
    try {
      const res = await getAllPlayersService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchPlayerDetails = createAsyncThunk(
  'fetch/playerDetail',
  async (userId, thunkApi) => {
    try {
      const res = await getPlayerDetailService(userId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createPlayer = createAsyncThunk(
  'create/player',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createPlayerService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updatePlayer = createAsyncThunk(
  'update/player',
  async ({ payload, onSuccess }, thunkApi) => {
    try {
      const res = await updatePlayerService(payload)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updatePlayerStatus = createAsyncThunk(
  'update/playerStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updatePlayerStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
