import { createSlice } from '@reduxjs/toolkit'
import { generateQr } from 'redux-thunk/thunk/Admin/Admin'
import { adminLogin, adminLogout, updatePassword } from 'redux-thunk/thunk/Auth/Auth'
import { fetchUserInformation } from 'redux-thunk/thunk/Users/User'
import { getLocalLanguage, setLocalLanguage } from 'utils/common-services/localstorage.services'
import { LANGUAGES } from 'utils/constants/index'

const defaultAuthState = {
  loginData: null,
  adminUserDetails: null,
  qrDetails: null,
  language: getLocalLanguage() || LANGUAGES[0].languageCode
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: defaultAuthState,
  reducers: {
    handleLanguageChange: (state, action) => {
      setLocalLanguage(action.payload)
      return {
        ...state,
        language: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLogin.fulfilled, (state, action) => {
        return {
          ...defaultAuthState,
          loginData: action.payload,
          language: action.payload?.additionalInfo?.languageCode || LANGUAGES[0].languageCode
        }
      })
      .addCase(adminLogout.fulfilled, (state, action) => {
        return {
          ...defaultAuthState
        }
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        return {
          ...defaultAuthState
        }
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          adminUserDetails: action.payload
        }
      })
      .addCase(generateQr.fulfilled, (state, action) => {
        return {
          ...state,
          qrDetails: action.payload
        }
      })
  }
})

export const { handleLanguageChange } = authSlice.actions

export default authSlice.reducer
