import PropTypes from 'prop-types'
import { Box, Divider } from '@mui/material'
import { DrawerHeader, StyledDrawer } from './styles'
import { ROUTE_PATHS } from 'utils/constants/index'

const AppDrawer = ({ children, openDrawer, handleDrawer, isMobileView, navigate }) => {
  return (
    <StyledDrawer
      anchor='left'
      variant={isMobileView ? 'temporary' : 'persistent'}
      open={openDrawer}
      hideBackdrop
    >
      <Box>
        <DrawerHeader>
          {/* <img
            style={{ width: '180px', margin: '15px auto', cursor: 'pointer' }}
            src='/images/logo/brand-logo.png' alt='logo'
            onClick={() => {
              navigate(ROUTE_PATHS.dashboard)
            }}
          /> */}
          <h2
            style={{ textAlign: 'center', width: '100%' }}
            onClick={() => {
              navigate(ROUTE_PATHS.dashboard)
            }}
          >
            FortuneBlock
          </h2>
        </DrawerHeader>
      </Box>
      <Box sx={{ overflow: 'auto' }}>
        <Divider sx={{ borderColor: 'white', margin: '10px 0px' }} light />
        {children}
      </Box>
    </StyledDrawer>
  )
}

export default AppDrawer

AppDrawer.defaultProps = {
  isMobileView: false
}

AppDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleDrawer: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  isMobileView: PropTypes.bool,
  navigate: PropTypes.func.isRequired
}
