import { lazy } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ROUTE_PATHS } from 'utils/constants/index'
import LoginIcon from '@mui/icons-material/Login'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined'
import Login from 'pages/Auth/Login'

export const routerList = [
  {
    id: uuidv4(),
    sectionName: 'home',
    sectionChilds: [
      {
        key: uuidv4(),
        label: 'dashboard',
        path: ROUTE_PATHS.dashboard,
        component: lazy(() => import('pages/Dashboard')),
        hasAuth: true,
        hasSideBarOption: true,
        icon: DashboardOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'dailyStatistics',
        path: ROUTE_PATHS.dailyStatistics,
        component: lazy(() => import('pages/DailyStatistics')),
        hasAuth: true,
        hasSideBarOption: true,
        icon: DashboardOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'System Settings',
        path: ROUTE_PATHS.gameSettings,
        component: lazy(() => import('pages/GameSettings')),
        hasAuth: true,
        hasSideBarOption: true,
        icon: SettingsOutlinedIcon,
        subMenus: [
          {
            key: uuidv4(),
            label: 'Game settings',
            path: ROUTE_PATHS.gameSettings,
            component: lazy(() => import('pages/GameSettings')),
            hasAuth: true,
            hasSideBarOption: true,
            icon: CircleOutlinedIcon
          }
        ]
      },
      // {
      //   key: uuidv4(),
      //   label: 'Admins',
      //   path: ROUTE_PATHS.admins,
      //   component: lazy(() => import('pages/AdminManagement')),
      //   hasAuth: true,
      //   hasSideBarOption: true,
      //   icon: DomainIcon,
      //   subMenus: []
      // },
      {
        key: uuidv4(),
        label: 'Players',
        path: ROUTE_PATHS.users,
        component: lazy(() => import('pages/PlayerManagement')),
        hasAuth: true,
        hasSideBarOption: true,
        icon: GroupsOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'Transactions',
        path: ROUTE_PATHS.transactions,
        component: lazy(() => import('pages/TransactionsList')),
        hasAuth: true,
        hasSideBarOption: true,
        icon: PaidOutlinedIcon,
        subMenus: []
      },
      // {
      //   key: uuidv4(),
      //   label: 'Withdraw Requests',
      //   path: ROUTE_PATHS.withdrawRequests,
      //   component: lazy(() => import('pages/WithdrawRequests')),
      //   hasAuth: true,
      //   hasSideBarOption: true,
      //   icon: FeedOutlinedIcon,
      //   subMenus: []
      // },
      {
        key: uuidv4(),
        label: 'Reports by Game',
        path: ROUTE_PATHS.gameReports,
        component: lazy(() => import('pages/GameReports')),
        hasAuth: true,
        hasSideBarOption: true,
        icon: InsightsOutlinedIcon,
        subMenus: []
      }
    ]
  },
  {
    id: uuidv4(),
    sectionName: null,
    sectionChilds: [
      {
        key: uuidv4(),
        label: 'login',
        path: ROUTE_PATHS.login,
        component: Login,
        hasAuth: false,
        hasSideBarOption: false,
        icon: LoginIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'reset-password',
        path: ROUTE_PATHS.resetPassword,
        component: lazy(() => import('pages/Auth/ResetPassword')),
        hasAuth: false,
        hasSideBarOption: false,
        icon: LoginIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'settings',
        path: `${ROUTE_PATHS.settings}/*`,
        component: lazy(() => import('pages/Settings')),
        hasAuth: true,
        hasSideBarOption: false,
        icon: LoginIcon,
        subMenus: []
      }
    ]
  },
  {
    id: uuidv4(),
    sectionName: null,
    sectionChilds: [
      {
        key: uuidv4(),
        label: 'profile',
        path: ROUTE_PATHS.profile,
        component: lazy(() => import('pages/Profile')),
        hasAuth: true,
        hasSideBarOption: false,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'settings',
        path: `${ROUTE_PATHS.settings}/*`,
        component: lazy(() => import('pages/Settings')),
        hasAuth: true,
        hasSideBarOption: false,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'change-password',
        path: ROUTE_PATHS.changePassword,
        component: lazy(() => import('pages/Auth/ChangePassword')),
        hasAuth: true,
        hasSideBarOption: false,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'create-user',
        path: ROUTE_PATHS.createUser,
        component: lazy(() => import('pages/CreatePlayer')),
        hasAuth: true,
        hasSideBarOption: false,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'edit-user',
        path: ROUTE_PATHS.editUser,
        component: lazy(() => import('pages/EditPlayer')),
        hasAuth: true,
        hasSideBarOption: false,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'edit-game-setting',
        path: ROUTE_PATHS.editGameSettings,
        component: lazy(() => import('pages/EditGameSetting')),
        hasAuth: true,
        hasSideBarOption: false,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'edit-game-setting',
        path: ROUTE_PATHS.usersDetail,
        component: lazy(() => import('pages/PlayerDetails')),
        hasAuth: true,
        hasSideBarOption: false,
        icon: CircleOutlinedIcon,
        subMenus: []
      }
      // {
      //   key: uuidv4(),
      //   label: 'create-admin',
      //   path: ROUTE_PATHS.createUser,
      //   component: lazy(() => import('pages/CreatePlayer')),
      //   hasAuth: true,
      //   hasSideBarOption: false,
      //   icon: CircleOutlinedIcon,
      //   subMenus: []
      // },
    ]
  }
]
