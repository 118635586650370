import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getDailyStatisticsService,
  getDashboardReportService
} from 'network/services/dashboard.service'

export const fetchDashboardReport = createAsyncThunk(
  'fetch/DashboardReport',
  async (data, thunkApi) => {
    try {
      const res = await getDashboardReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchDailyStatisticsReport = createAsyncThunk(
  'fetch/DailyStatistics',
  async (data, thunkApi) => {
    try {
      const res = await getDailyStatisticsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
