import { ADMIN_ID, TOKEN, ROLE } from 'utils/constants/index'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

// 24 hour * 60 minutes of every hour
const COOKIE_EXPIRE_MIN = 24 * 60

/* ==========================================================================
  Auth Token
========================================================================== */
export const getAuthToken = () => {
  return cookies.get(TOKEN)
}

export const setAuthToken = (authAccessToken) => {
  cookies.set(TOKEN, authAccessToken, {
    path: '/',
    expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000))
  })
}

export const removeAuthToken = () => {
  cookies.remove(TOKEN, { path: '/' })
}

/* ==========================================================================
  Operator ID
========================================================================== */
export const getAdminId = () => {
  return cookies.get(ADMIN_ID)
}

export const setAdminId = (operatorId) => {
  cookies.set(ADMIN_ID, operatorId, {
    path: '/',
    expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000))
  })
}

export const removeAdminId = () => {
  cookies.remove(ADMIN_ID, { path: '/' })
}

/* ==========================================================================
  Operator ID
========================================================================== */
export const getOperatorRole = () => {
  return cookies.get(ROLE)
}

export const setOperatorRole = (role) => {
  cookies.set(ROLE, role, {
    path: '/',
    expires: new Date((Date.now() + COOKIE_EXPIRE_MIN * 60 * 1000))
  })
}

export const removeOperatorRole = () => {
  cookies.remove(ROLE, { path: '/' })
}

/* ==========================================================================
  Signin Action
========================================================================== */
export const signIn = ({ token, adminId, role }) => {
  setAuthToken(token)
  setAdminId(adminId)
  setOperatorRole(role)
}

/* ==========================================================================
  Signout Action
========================================================================== */
export const signOut = () => {
  removeAuthToken()
  removeAdminId()
  removeOperatorRole()
}
