import AppRoutes from 'routes'
import { styled, CssBaseline } from '@mui/material'
import { connect } from 'react-redux'
import AppLoader from 'components/ui-kit/AppLoader'
import AppToaster from 'components/ui-kit/AppToaster'
import { LOADER_TYPE } from 'utils/constants'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorHandler from 'components/layout/ErrorHandler/index'

const App = ({ pageLoading }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <>
        <CssBaseline />
        {
          pageLoading &&
            <StyledLoaderDiv>
              <AppLoader variant={LOADER_TYPE.SCALE} />
            </StyledLoaderDiv>
        }
        <StyledBodyContainer pageloading={pageLoading ? 1 : 0}>
          <AppToaster />
          <AppRoutes pageLoader={pageLoading} />
        </StyledBodyContainer>
      </>
    </ErrorBoundary>
  )
}

const StyledLoaderDiv = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  zIndex: 1
}))

const StyledBodyContainer = styled('div')(({ theme, pageloading }) => (pageloading
  ? {
      filter: 'blur(3px)'
    }
  : {}))

const mapStateToProps = (state) => {
  return {
    pageLoading: state.loader.pageLoader
  }
}

export default connect(mapStateToProps)(App)
