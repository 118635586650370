import { createAsyncThunk } from '@reduxjs/toolkit'
import { updateAdminDetailsService } from 'network/services/admins.service'
import { getUserInfoService } from 'network/services/users.service'

/**
 * Fetch User info who is logged in
 */
export const fetchUserInformation = createAsyncThunk(
  'fetch/UserInfo',
  async (_, thunkApi) => {
    try {
      const res = await getUserInfoService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update User info who is logged in
 */
export const updateUserInformation = createAsyncThunk(
  'update/UserInfo',
  async ({ email, firstName, lastName, onSuccess, onError }, thunkApi) => {
    try {
      const res = await updateAdminDetailsService({ email, firstName, lastName })
      onSuccess()
      return res
    } catch (error) {
      onError()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

// /**
//  * Update User Language Thunk
//  */
// export const updateUserLanguage = createAsyncThunk('update/language', async ({ languageCode }, thunkApi) => {
//   try {
//     const res = await updateUserInfoService({ languageCode })
//     return res
//   } catch (error) {
//     return thunkApi.rejectWithValue(error[0].description)
//   }
// })
